import detailDefault from '../../detailDefault';

export default class detailAccounts extends detailDefault {
    /**
     * @param {sAction} sAction
     * @param {object} data
     */
    load(sAction, data) {
        this.updateDetailApiVisibility(sAction, data);

        sAction.dsClear();
        sAction.dsAdd('set', data.prefix+'/fields/seeky_activity/def/type', 'HTMLText');
        sAction.dsAdd('set', data.prefix+'/fields/seeky_activity/def/readonly', true);
        sAction.dsProcess();
    }

    /**
     *
     * @param {sAction} sAction
     * @param {object} data
     */
    update(sAction, data) {
        switch (data.field) {
            case 'billing_address_country':
                this.updateDetailApiVisibility(sAction, data);
                break;
            case 'lat':
            case 'lon':
                this.updateCoordinates(sAction, data);
                break;
        }
    }

    /**
     * fce skryva/odkryva detail fieldy podle zeme, jestli je zeme CZECH REPUBLIC
     * @param {sAction} sAction
     * @param {object} data
     */
    updateDetailApiVisibility(sAction, data) {
        let address;
        if (data.value) {
            address = data.value;
        } else {
            address = sAction.dataGet(data.prefix + '/fields/billing_address_country/value');
        }

        /**
         * @param {string} fieldName
         * @param {string} fieldValue
         */
        const updateFieldApiValue = (fieldName, fieldValue) => {
            if (sAction.dataGet(data.prefix + '/fields/'+fieldName) !== undefined) {
                sAction.dsAdd('set', data.prefix + '/fields/'+fieldName+'/def/showDetailApi', fieldValue);
                // sAction.dsAdd("set", data.prefix + "/fields/"+fieldName+"/def/type", fieldValue);
            }
        };

        /**
         * Set definition required based on parameter to ICO (ticker_symbol) field
         * @param {boolean} required
         */
        const updateDefIcoRequired = (required) => {
            sAction.dsAdd('set', data.prefix + '/fields/ticker_symbol/def/required', required);
        };

        sAction.dsClear();
        if (!address || address === 'CZECH REPUBLIC') {
            // zobrazi ikony
            updateFieldApiValue('name', 'ApiName');
            updateFieldApiValue('account_name', 'ApiName');
            updateFieldApiValue('ticker_symbol', 'ApiICO');
            updateFieldApiValue('sic_code', 'ApiDIC');
            updateFieldApiValue('billing_address_street', 'ApiStreet');
            updateFieldApiValue('shipping_address_street', 'ApiStreet');
            updateFieldApiValue('billing_address_postalcode', 'ApiPSC');
            updateFieldApiValue('shipping_address_postalcode', 'ApiPSC');
            updateDefIcoRequired(true);
        } else if (address === 'SLOVAKIA') {
            // skryje ikony
            updateFieldApiValue('name', 'ApiNameSK');
            updateFieldApiValue('account_name', 'ApiNameSK');
            updateFieldApiValue('ticker_symbol', 'ApiICOSlovakia');
            updateFieldApiValue('sic_code', '');
            updateFieldApiValue('billing_address_street', 'ApiStreet');
            updateFieldApiValue('shipping_address_street', 'ApiStreet');
            updateFieldApiValue('billing_address_postalcode', 'ApiPSC');
            updateFieldApiValue('shipping_address_postalcode', 'ApiPSC');
            updateDefIcoRequired(true);
        } else if (address === 'POLAND' && sAction.dataGet(`${data.prefix}/customData/companyCodeApi/gusApi`)) {
        // GUS - Polsky ares
            updateFieldApiValue('name', 'ApiNameOthers');
            updateFieldApiValue('account_name', 'ApiNameOthers');
            updateFieldApiValue('ticker_symbol', 'ApiNIPPoland');
            updateFieldApiValue('sic_code', '');
            updateFieldApiValue('billing_address_street', 'ApiStreet');
            updateFieldApiValue('shipping_address_street', 'ApiStreet');
            updateFieldApiValue('billing_address_postalcode', '');
            updateFieldApiValue('shipping_address_postalcode', '');
            updateDefIcoRequired(false);
        } else {
            // skryje ikony
            updateFieldApiValue('name', 'ApiNameOthers');
            updateFieldApiValue('account_name', '');
            updateFieldApiValue('ticker_symbol', '');
            updateFieldApiValue('sic_code', '');
            updateFieldApiValue('billing_address_street', '');
            updateFieldApiValue('shipping_address_street', '');
            updateFieldApiValue('billing_address_postalcode', '');
            updateFieldApiValue('shipping_address_postalcode', '');
            updateDefIcoRequired(false);
        }

        // Skryvani/odkryvani tabu Finstat kdyz je nebo neni zeme Slovensko - KVULI EDITVIEW SE NEBUDOU ZADNE TABY SCHOVAVAT
        /* const tabs = sAction.dataGet(data.prefix + '/tabs');
        if (tabs) {
            const finstatIndex = tabs.findIndex((i) => i.name === 'LBL_DETAILVIEW_PANEL_FINSTAT');
            if (finstatIndex !== -1) {
                if (address !== 'SLOVAKIA') {
                    sAction.dsAdd('set', data.prefix + '/tabs/' + finstatIndex + '/hidden', true);
                } else {
                    sAction.dsAdd('set', data.prefix + '/tabs/' + finstatIndex + '/hidden', false);
                }
            }
        } */
        sAction.dsProcess();
    }

    /**
     * @param {sAction} sAction
     * @param {object} data
     */
    updateCoordinates(sAction, data) {
        // if field lat or lon contains letter at the end so trim the letters off
        let value = data.value;
        if (value) {
            value = value.replace(/[a-zA-Z]/g, '');
            const field = data.field;
            sAction.dsClear();
            sAction.dsAdd('set', data.prefix + '/fields/' + field + '/value', value);
            sAction.dsAdd('set', data.prefix + '/changes/fields/' + field, value);
            sAction.dsProcess();
        }
    }
}
