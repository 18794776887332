import moment from 'moment';
import detailDefault from '../../detailDefault';

export default class detailTasks extends detailDefault {
    load(sAction, data) {
        this.checkAllDay(sAction, data);
        sAction.dsClear();
        sAction.dsAdd('set', `${data.prefix}/fields/date_recidivism_end/def/visible`, true);
        sAction.dsAdd('set', `${data.prefix}/fields/recidivism_parent_id_c/def/visible`, false);
        sAction.dsAdd('set', `${data.prefix}/fields/task_id1_c/def/visible`, false);
        sAction.dsProcess();
        this.updateVisibilityFields(sAction, data);
        this.setRecidivism(sAction, data);
    }

    update(sAction, data) {
        switch (data.field) {
            case 'all_day':
                this.checkAllDay(sAction, data);
                break;
            case 'date_start':
            case 'date_due':
                this.checkSwitchToAllDay(sAction, data);
                break;
            case 'recidivism':
                this.setRecidivism(sAction, data);
                break;
            case 'repetitions_till':
                this.updateVisibilityFields(sAction, data)
                break;
            case 'recidivism_by':
                this.updateDaysVisibility(sAction, data);
                break;
        }
        const dateDue = sAction.dataGet(data.prefix + '/fields/date_due/value');
        if (data.field === 'date_start' && !dateDue) {
            const dateDue = new Date(data.value);
            dateDue.setHours(dateDue.getHours() + 1);
            const formattedDateDue = moment(dateDue).format('YYYY-MM-DD HH:mm:ss');
            sAction.dataSet(data.prefix + '/fields/date_due/value', formattedDateDue);
            sAction.dataSet(data.prefix + '/changes/fields/date_due', formattedDateDue);
        }
        sAction.dsProcess();
    }

    /**
     *
     * @param {class} sAction
     * @param {object} data
     */
    checkAllDay(sAction, data) {
        if (parseInt(sAction.dataGet(data.prefix + '/fields/all_day/value')) === 1) {
            let valDateStart = sAction.dataGet(data.prefix + '/fields/date_start/value');
            let valDateEnd = sAction.dataGet(data.prefix + '/fields/date_due/value');

            if (valDateStart) {
                valDateStart = moment(new Date(valDateStart)).format('YYYY-MM-DD');
            }
            if (valDateEnd) {
                valDateEnd = moment(new Date(valDateEnd)).format('YYYY-MM-DD');
            }

            sAction.dsClear();
            sAction.dsAdd('set', data.prefix + '/fields/date_start/value', valDateStart);
            sAction.dsAdd('set', data.prefix + '/fields/date_start/def/type', 'date');
            sAction.dsAdd('set', data.prefix + '/fields/date_due/value', valDateEnd);
            sAction.dsAdd('set', data.prefix + '/fields/date_due/def/type', 'date');
            sAction.dsProcess();
        } else {
            let valDateStart = sAction.dataGet(data.prefix + '/fields/date_start/value');
            let valDateEnd = sAction.dataGet(data.prefix + '/fields/date_due/value');

            if (valDateStart) {
                valDateStart = moment(new Date(valDateStart)).format('YYYY-MM-DD HH:mm:ss');
            }
            if (valDateEnd) {
                valDateEnd = moment(new Date(valDateEnd)).format('YYYY-MM-DD HH:mm:ss');
            }

            sAction.dsClear();
            sAction.dsAdd('set', data.prefix + '/fields/date_start/value', valDateStart);
            sAction.dsAdd('set', data.prefix + '/fields/date_due/value', valDateEnd);
            sAction.dsAdd('set', data.prefix + '/fields/date_start/def/type', 'datetimecombo');
            sAction.dsAdd('set', data.prefix + '/fields/date_due/def/type', 'datetimecombo');
            sAction.dsProcess();
        }
    }

    /**
     *
     * @param {class} sAction
     * @param {object} data
     */
    checkSwitchToAllDay(sAction, data) {
        // when date diff is more than one day, switch to all day format
        const allDay = sAction.dataGet(data.prefix + '/fields/all_day/value');
        let startDate = sAction.dataGet(data.prefix + '/fields/date_start/value');
        let endDate = sAction.dataGet(data.prefix + '/fields/date_due/value');

        if ((parseInt(allDay) === 0 || allDay === null) && startDate !== null && endDate !== null) {
            startDate = moment(startDate).format('YYYY-MM-DD');
            endDate = moment(endDate).format('YYYY-MM-DD');

            // eslint-disable-next-line eqeqeq
            if (startDate !== endDate && startDate !== 'Invalid date' && endDate !== 'Invalid date') {
                // popup switch to allday format?
                sAction.confirm(sAction.translate('LBL_SWITCH_TO_ALLDAY', 'Meetings'), () => {
                    sAction.popupHide();
                    sAction.dsClear();
                    let valDateStart = sAction.dataGet(data.prefix + '/fields/date_start/value');
                    let valDateEnd = sAction.dataGet(data.prefix + '/fields/date_due/value');
                    if (valDateStart) {
                        valDateStart = moment(new Date(valDateStart)).format('YYYY-MM-DD');
                    }
                    if (valDateEnd) {
                        valDateEnd = moment(new Date(valDateEnd)).format('YYYY-MM-DD');
                    }

                    sAction.dsAdd('set', data.prefix + '/fields/all_day/value', '1');
                    sAction.dsAdd('set', data.prefix + '/changes/fields/all_day', '1');
                    sAction.dsAdd('set', data.prefix + '/fields/date_start/value', valDateStart);
                    sAction.dsAdd('set', data.prefix + '/fields/date_start/def/type', 'date');
                    sAction.dsAdd('set', data.prefix + '/fields/date_start/actEdit', false);
                    sAction.dsAdd('set', data.prefix + '/fields/date_due/value', valDateEnd);
                    sAction.dsAdd('set', data.prefix + '/fields/date_due/def/type', 'date');
                    sAction.dsAdd('set', data.prefix + '/fields/date_due/actEdit', false);
                    sAction.dsProcess();
                });
            }
        }
    }

    /**
     *
     * @param {sAction} sAction
     * @param {{}} data
     * @returns {boolean}
     */
    beforeSave(sAction, data) {
        const recidivism = sAction.dataGet(data.prefix + '/fields/recidivism_parent_id_c/value');
        if (!recidivism) {
            return true;
        } else {
            sAction.popup('SelectSaveTask', data);
            return false;
        }
    }

    /**
     *
     * @param {sAction} sAction
     * @param {{}} data
     */
    updateVisibilityFields(sAction, data) {
        const repetitionsTill = sAction.dataGet(data.prefix + '/fields/repetitions_till/value');
        switch (repetitionsTill) {
            case 'ByDate':
                sAction.dsClear();
                sAction.dsAdd('set', `${data.prefix}/fields/date_recidivism_end/def/visible`, true);
                sAction.dsAdd('set', `${data.prefix}/fields/recidivism_amount/def/visible`, false);
                sAction.dsProcess();
                break;
            case 'ByNumber':
                sAction.dsClear();
                sAction.dsAdd('set', `${data.prefix}/fields/date_recidivism_end/def/visible`, false);
                sAction.dsAdd('set', `${data.prefix}/fields/recidivism_amount/def/visible`, true);
                sAction.dsProcess();
                break;
            case 'Inf':
            default:
                sAction.dsClear();
                sAction.dsAdd('set', `${data.prefix}/fields/date_recidivism_end/def/visible`, false);
                sAction.dsAdd('set', `${data.prefix}/fields/recidivism_amount/def/visible`, false);
                sAction.dsProcess();
                break;
        }
    }

    /**
     *
     * @param {sAction} sAction
     * @param {{}} data
     */
    setRecidivism(sAction, data) {
        const recidivism = sAction.dataGet(data.prefix + '/fields/recidivism/value');
        console.log('recidivism - change', recidivism); // TODO SMAZAT
        if (['1', 1].includes(recidivism)) {
            sAction.dsClear();
            sAction.dsAdd('set', `${data.prefix}/fields/recidivism_by/def/visible`, true);
            sAction.dsAdd('set', `${data.prefix}/fields/recidivism_period/def/visible`, true);
            sAction.dsAdd('set', `${data.prefix}/fields/repetitions_till/def/visible`, true);
            sAction.dsAdd('set', `${data.prefix}/fields/recidivism_days/def/visible`, true);
            sAction.dsProcess();
            this.updateVisibilityFields(sAction, data);
            this.updateDaysVisibility(sAction, data);
            sAction.dsProcess();
        } else {
            sAction.dsClear();
            sAction.dsAdd('set', `${data.prefix}/fields/recidivism_amount/def/visible`, false);
            sAction.dsAdd('set', `${data.prefix}/fields/date_recidivism_end/def/visible`, false);
            sAction.dsAdd('set', `${data.prefix}/fields/recidivism_by/def/visible`, false);
            sAction.dsAdd('set', `${data.prefix}/fields/recidivism_period/def/visible`, false);
            sAction.dsAdd('set', `${data.prefix}/fields/repetitions_till/def/visible`, false);
            sAction.dsAdd('set', `${data.prefix}/fields/recidivism_days/def/visible`, false);
            sAction.dsProcess();
        }
    }

    /**
     *
     * @param {sAction} sAction
     * @param {{}} data
     */
    updateDaysVisibility(sAction, data) {

        const recidivismBy = data.value ?? sAction.dataGet(data.prefix + '/fields/recidivism_by/value');
        console.log('kokos', data.value, recidivismBy, data); // TODO SMAZAT
        if (recidivismBy === 'week') {
            sAction.dsAdd('set', `${data.prefix}/fields/recidivism_days/def/visible`, true);
        } else {
            sAction.dsAdd('set', `${data.prefix}/fields/recidivism_days/def/visible`, false);
        }
    }
}
