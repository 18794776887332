/**
 *
 * @param {{}}data
 */
export default function acmCostsCreateAcmOrder(data) {
    const params = {action: 'acmCostsCreateAcmOrder', record: data.id};
    this.rest.fetchData('customAction', 'POST', params, false).then((response) => {
        this.href(`#detail/acm_orders/${response.data.record}`);
    }).catch((error) => {
        this.error(error.text);
    });
}
