/**
 *
 * @param {object} data
 */
export default function changeOppStage(data){

    /**
     *
     * @param {string} prefix
     * @param {string} salesStageNew
     * @param {string} thingNeeded
     */
    const changeIfHave = (prefix, salesStageNew, thingNeeded) => {
        let restCall = null;
        let label = '';
        switch (thingNeeded) {
            case 'Activity':
                restCall = 'opportunitiesSalesStageChangeControlActivity';
                label = 'LBL_ADD_ACTIVITY';
                break;
            case 'Quotes':
                restCall = 'opportunitiesSalesStageChangeControlQuotes';
                label = 'LBL_ADD_QUOTE';
                break;
        }

        this.load();
        const id = this.dataGet(prefix+'/id');
        if (!id) {
            this.error(this.translate('LBL_SAVE_OPPORTUNITY', 'Opportunities'));
            this.unLoad();
            return;
        }
        this.rest.post(restCall, {id: id}, (data) =>{
            let canChange = false;
            const text = '';
            if (data['status']) {
                canChange = data?.['message']?.['data']?.['canChange'];
            }
            if (!canChange) {
                // nelze ulozit
                this.error(this.translate(label, 'Opportunities'));
                this.unLoad();
                return;
            }

            // pokud by bylo treba kontrolovat pritomnpost produktu na prilezitostech
            // if(data["message"]["text"] !== "obsahuje dokument" && data["message"]["text"] !== "obsahuje email"){
            //   updateSalesStage(this,prefix,salesStageNew);
            //   this.unLoad();
            //   return;
            // }
            // // obsahuje document/email => musi mit vyplneny produkty
            // canChange = false;
            // let productData = this.dataGet(prefix+"/customData/productData/lines");
            // productData = Array.from(productData.values());
            // productData.forEachObject((product,index) =>{
            //   if(product.get("discount_price") !== 0){
            //     canChange = true;
            //   }
            // });
            // if(canChange){
            //   updateSalesStage(this,prefix,salesStageNew);
            // } else {
            //   this.error(this.translate('LBL_ADD_PRODUCTS','Opportunities'));
            // }
            changeStage(salesStageNew);
            this.unLoad();
        }); // end of rest call
    }; // end of changeIfHave()

    /**
     *
     * @param {string} newStage
     */
    const changeStage = (newStage) => {
        this.dsAdd( 'set', `${data.prefix}/changes/fields/sales_stage`, newStage);
        this.dsAdd('set', `${data.prefix}/fields/sales_stage/value`, newStage);
        // save object button paramData:
        /*
        {
          "prefix": "view",
          "module": "Opportunities",
          "id": "e1405df6-2321-c9fd-1155-663894064c34",
          "type": null,
          "name": "asdasd",
          "buttonId": "save_record",
          "canSave": true,
          "view": "detail"
        }
        */
        this.dsProcess();
    };

    const stageNew = data.stage;
    const stageOld = this.dataGet(`${data.prefix}/fields/sales_stage/value`);

    if (['Closed canceled client', 'Closed canceled me'].includes(stageNew)) {
        changeStage(stageNew);
        return;
    }

    switch (stageOld) {
        case 'Prospecting':
            if (stageNew === 'Qualification') {
                changeStage(stageNew);
                return;
            }
            this.error('Lze přejít pouze do kvalifikace nebo zrušeno');
            return;
        case 'Qualification':
            if (['Value Proposition', 'Negotiation/Review', 'Suspended'].includes(stageNew)) {
                changeIfHave(data.prefix, stageNew, 'Quotes');
                return;
            }
            this.error('Lze přejít pouze do cenové nabídky, odloženo nebo zrušeno');
            return;
        case 'Value Proposition':
            if (stageNew === 'Negotiation/Review') {
                changeStage(stageNew);
                return;
            }
        // no break;
        // eslint-disable-next-line no-fallthrough
        case 'Negotiation/Review':
            if (['Closed Won', 'Closed Lost'].includes(stageNew)) {
                changeStage(stageNew);
                return;
            }
            this.error('Lze přepnout pouze do vyhráno, prohráno nebo zrušeno');
            return;
        case 'Suspended':
            console.warn('Suspended - no action'); // TODO SMAZAT
            return;
        default:
            console.warn('No action defined', stageOld, '-->', stageNew);
    }
}
