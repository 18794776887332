import detailDefault from '../../detailDefault';

/* eslint camelcase: "off" */
export default class detailacm_invoices extends detailDefault {
    load(sAction, data) {
        if (data.prefix === 'rightPanel/data/detail') {
            sAction.dataSet(data.prefix + '/fields/cena_dph_celkem/def/readonly', true);
            sAction.dataSet(data.prefix + '/fields/cena_bez_dph_celkem/def/readonly', true);
            sAction.dataSet(data.prefix + '/fields/cena_s_dph_celkem/def/readonly', true);
        }

        this.lockReadOnly(sAction, data);
    }

    /**
     * @param {any} sAction
     * @param {any} data
     */
    newSubRecord(sAction, data) {
        const currency = sAction.dataGet(data.prefix+'/fields/currency_id/value');
        if (sAction.detailPredefinedFields == null) {
            sAction.detailPredefinedFields = [];
        }
        sAction.detailPredefinedFields.push({
            'type': 'field',
            'name': 'currency_id',
            'value': currency,
        });
    }

    /**
     * @param {any} sAction
     * @param {any} data
     * @return {boolean}
     */
    beforeSave(sAction, data) {
        if (data.prefix === 'view') {
            if ((!sAction.dataGet('view/customData/customLines') ||
                sAction.dataGet('view/customData/customLines').has('lines') === false)
            ) {
                return this.noRowsErrorMsg(sAction);
            }
            const lines = sAction.dataGet('view/customData/customLines/lines', null, true);
            let linLen = 0;
            if (lines !== undefined) {
                linLen = Object.keys(lines).length;

                lines.forEachObject((data) => {
                    if (parseInt(data.deleted) === 1) {
                        linLen--;
                    }
                });
            }

            if (lines !== undefined) {
                if (Object.keys(lines).length === 0 || linLen === 0) {
                    return this.noRowsErrorMsg(sAction);
                } else {
                    return true;
                }
            } else {
                return true;
            }
        }

        return true;
    }

    /**
     * noRowsErrorMsg
     * @param {any} sAction
     * @return {boolean}
     */
    noRowsErrorMsg(sAction) {
        sAction.alert(sAction.translate('LBL_NO_ROWS_MSG', 'acm_invoices'));
        return false;
    }

    /**
     * lockReadOnly if stav_uhrazeni is paid or sent
     * @param {any} sAction
     * @param {any} data
     */
    lockReadOnly(sAction, data) {
        const state = sAction.dataGet(data.prefix + '/fields/stav_uhrazeni/value');
        const lockedStates = ['odeslana', 'uhrazeno'];
        const allowedFields = [
            'stav_uhrazeni',
            'datum_uhrazeni',
            'assigned_user_id',
            'assigned_user_name',
            'text_pred',
            'objedn_cislo',
        ];

        if (lockedStates.includes(state)) {
            const fields = sAction.dataGet(data.prefix + '/fields');

            sAction.dsClear();

            fields.forEach((field) => {
                if (!allowedFields.includes(field.name)) {
                    sAction.dsAdd('set', `${data.prefix}/fields/${field.name}/def/readonly`, true);
                }
            });

            sAction.dsAdd('set', `${data.prefix}/customData/customLines/def/readonly`, true);
            sAction.dsProcess();
        }
    }

    /**
     *
     * @param {any} sAction
     * @param {any} data
     */
    updateDetailLine(sAction, data) {
        switch (data.field) {
            case 'name':
                if (data.value.id) {
                    sAction.load();
                    const fields = {cena_bez_dph_kus: 'list_price', kod: 'mft_part_num'};
                    const searchData = {
                        module: 'ProductTemplates',
                        fields: ['id'],
                        getFields: Object.values(fields),
                        value: data.value.id,
                        limit: 999999,
                    };

                    sAction.quickSearch(searchData, (returnData) => {
                        sAction.dsClear();
                        fields.forEachObject((templateName, lineName) => {
                            data.saveField(returnData[0][templateName], lineName, true);
                        });
                        sAction.dsProcess();
                        sAction.unLoad();
                    });
                }
                break;
        }
    }
}
