/**
 * @param {object} data
 * @this sAction
 * @button DetailView/Accounts
 * @render DetailView
 */
export default function accCreateOpportunity(data) {
    this.detailPredefinedFields = [];
    // nazev firmy
    this.detailPredefinedFields.push({
        type: 'relate_simple',
        fieldName: 'billing_account_name',
        name: 'billing_account_id',
        value: {'id': data.id, 'name': data.name},
    });

    this.detailNewRecord({
        module: 'Quotes',
        way: data.prefix,
    });
}
