import detailDefault from '../../detailDefault';

/* eslint camelcase: "off" */
export default class detailacm_invoices extends detailDefault {
    load(sAction, data) {
        const id = sAction.dataGet(`${data.prefix}/fields/id/value`);
        if (!id) {
            const conversionRate = sAction.dataGet(`${data.prefix}/fields/kurz/value`);
            sAction.dataSet(`${data.prefix}/changes/fields/kurz`, conversionRate);
        }
    }

    /**
     *
     * @param {sAction} sAction
     * @param {{}} data
     */
    update(sAction, data) {
        let conversionRate = 1;
        let costCzk = 1;
        sAction.dsClear();
        switch (data.field) {
            case 'cost_czk':
                conversionRate = sAction.dataGet(`${data.prefix}/fields/kurz/value`);
                this.setEur(sAction, data, data.value, conversionRate);
                break;
            case 'kurz':
                costCzk = sAction.dataGet(`${data.prefix}/fields/cost_czk/value`);
                this.setEur(sAction, data, costCzk, data.value);
                break;
        }
        sAction.dsProcess();
    }

    /**
     * @warning simmilar calculation on ProductTemplates detailLines
     *
     * @param {sAction} sAction
     * @param {{}} data
     * @param {number} costCzk
     * @param {number} conversionRate
     */
    setEur = (sAction, data, costCzk = 1, conversionRate = 1) => {
        const costEur = costCzk / conversionRate;
        sAction.dsAdd('set', `${data.prefix}/fields/cost_eur/value`, costEur);
        sAction.dsAdd('set', `${data.prefix}/changes/fields/cost_eur`, costEur);
    };
}
